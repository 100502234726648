export const taskCounterTheme = {
  parts: ["container", "indicator", "label"],
  baseStyle: {
    container: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    indicator: {
      borderRadius: "full",
      borderWidth: "6px",
      borderColor: "gray.2a00",
      background: "gray.600",
      borderStyle: "solid",
      display: "flex",
      color: "white",
      alignItems: "center",
      justifyContent: "center",
      fontWeight: "medium",
    },
    label: {
      fontWeight: "medium",
      color: "text",
    },
  },
  sizes: {
    sm: {
      container: {
        gap: "padding.sm",
      },
      indicator: {
        width: "30px",
        height: "30px",
        fontSize: "12px",
        borderWidth: "5px",
      },
      label: {
        fontSize: "sm",
      },
    },
    md: {
      container: {
        gap: "padding.base",
      },
      indicator: {
        width: "38px",
        height: "38px",
        fontSize: "sm",
        borderWidth: "8px",
      },
      label: {
        fontSize: "md",
      },
    },
    lg: {
      container: {
        gap: "padding.base",
      },
      indicator: {
        width: "42px",
        height: "42px",
        borderWidth: "8px",
        fontSize: "md",
      },
      label: {
        fontSize: "lg",
      },
    },
  },
  variants: {
    pending: {
      indicator: {
        bg: "pending.solid",
        borderColor: "pending.muted",
        color: "pending.contrast",
      },
    },
    primary: {
      indicator: {
        bg: "primary.solid",
        borderColor: "primary.muted",
        color: "primary.contrast",
      },
    },
    secondary: {
      indicator: {
        bg: "secondary.solid",
        borderColor: "secondary.subtle",
        color: "secondary.contrast",
      },
    },
    warning: {
      indicator: {
        bg: "warning.solid",
        borderColor: "warning.muted",
        color: "warning.contrast",
      },
    },
    error: {
      indicator: {
        bg: "error.solid",
        borderColor: "error.muted",
        color: "error.contrast",
      },
    },
    info: {
      indicator: {
        bg: "info.solid",
        borderColor: "info.muted",
        color: "info.contrast",
      },
    },
    success: {
      indicator: {
        bg: "success.solid",
        borderColor: "success.muted",
        color: "success.contrast",
      },
    },
  },
  defaultProps: {
    size: "md",
  },
};
