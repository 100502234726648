import { createMultiStyleConfigHelpers } from "@chakra-ui/react";

const helpers = createMultiStyleConfigHelpers(["body", "header"]);

const EaiCard = helpers.defineMultiStyleConfig({
  baseStyle: {
    body: {
      borderRadius: "base",
      overflow: "hidden",
      bg: "white",
      boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.15)",
    },
    header: {
      py: "padding.base",
      px: "padding.lg",
      borderTopRadius: "base",
      alignItems: "center",
      fontWeight: "bold",
      borderBottom: "1px solid",
      borderBottomColor: "gray.300",
    },
  },
  variants: {
    primary: {
      body: {
        bg: "white",
        color: "text",
      },
      header: {
        bg: "primary.base",
        color: "primary.inverted",
        borderBottomColor: "gray.300",
        "& a, & button": {
          color: "primary.inverted",
        },
      },
    },
    secondary: {
      body: {
        bg: "secondary.background",
        color: "secondary.color",
      },
      header: {
        bg: "secondary.headerBackground",
        color: "black",
        borderBottomColor: "gray.300",
        "& a, & button": {
          color: "secondary.headerColor",
        },
      },
    },
    warning: {
      body: {
        bg: "warning.background",
        color: "warning.color",
      },
      header: {
        bg: "warning.headerBackground",
        color: "warning.headerColor",
        "& a, & button": {
          color: "warning.headerColor",
        },
      },
    },
    success: {
      body: {
        bg: "success.background",
        color: "success.color",
      },
      header: {
        bg: "success.headerBackground",
        color: "success.headerColor",
        "& a, & button": {
          color: "success.headerColor",
        },
      },
    },
    alert: {
      body: {
        bg: "alert.background",
        color: "alert.color",
      },
      header: {
        bg: "alert.headerBackground",
        color: "alert.headerColor",
        "& a, & button": {
          color: "alert.headerColor",
        },
      },
    },
    graph: {
      body: {
        bg: "white",
        color: "black",
      },
      header: {
        bg: "graph.headerBackground",
        color: "graph.color",
        "& a, & button": {
          color: "graph.headerColor",
        },
      },
    },
    timeline: {
      body: {
        bg: "white",
        color: "black",
      },
      header: {
        bg: "timeline.headerBackground",
        color: "black",
      },
    },
    neutral: {
      body: {
        bg: "neutral.background",
        color: "black",
      },
      header: {
        bg: "neutral.headerBackground",
        color: "neutral.headerColor",
        a: {
          color: "neutral.headerColor",
        },
      },
    },

    demo: {
      header: {
        bg: "orange.200",
        color: "blackAlpha.900",
      },
    },
  },

  defaultProps: {},
});
export default EaiCard;
